import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'server/pages';
import { window } from 'global';

import SocialValueDropdownOption from './SocialValueDropdownOption';

const SocialValueDropdown = ({ image, headLabel, links, mainLink, clickOutside }) => {
  const [isOpen, toggleMenu] = useState(false);

  const iOS = () => {
    // iOS will simulate mouseenter events, disable that event handler on iOS
    if (window && window.navigator && window.navigator.userAgent.match(/iPhone|iPad/)) {
      return true;
    }
    return false;
  };

  const handleMouseEnter = () => {
    if (iOS()) {
      return;
    }
    toggleMenu(true);
  };

  const handleMouseLeave = () => {
    if (iOS()) {
      return;
    }
    toggleMenu(false);
  };

  const handleClick = e => {
    e.stopPropagation();

    if (e.target.id === 'collapse-arrow') {
      toggleMenu(!isOpen);
      return false;
    }
    return false;
  };

  return (
    <li className="social-value-dropdown nav-item">
      <Link route={mainLink}>
        <a
          id="menu-link"
          className="nav-link has-drop-down-a"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          role="presentation"
          onClick={e => {
            handleClick(e);
            clickOutside(true);
          }}
        >
          <span className="icon-wrap">
            <img src={image} width="26" height="auto" alt={headLabel} />
          </span>
          {headLabel}
        </a>
      </Link>
      <span
        id="collapse-arrow"
        className={`collapse-arrow ${isOpen ? 'collapse-arrow-rotated' : ''}`}
        onClick={handleClick}
        role="button"
        tabIndex="-1"
      >
        &#8249;
      </span>
      {links && (
        <div
          className={`dropdown-menu setting-drop ${isOpen ? 'show' : ''}`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <ul className="drop-list social-value-menu">
            {links.map(({ id, route, label }, index) => (
              <SocialValueDropdownOption
                key={id + mainLink + index}
                route={route}
                label={label}
                clickOutside={clickOutside}
              />
            ))}
          </ul>
        </div>
      )}
    </li>
  );
};

SocialValueDropdown.propTypes = {
  image: PropTypes.string,
  mainLink: PropTypes.string,
  headLabel: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      route: PropTypes.string,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    }),
  ),
  clickOutside: PropTypes.func.isRequired,
};

SocialValueDropdown.defaultProps = {
  headLabel: 'Social value',
  // links: [
  //   { id: 1, route: '/social-value/residential-programme', label: 'Residential programme' },
  //   { id: 2, route: '/social-value/hmp-academies', label: 'HMP Academies' },
  //   { id: 3, route: '/social-value/food-re-distribution', label: 'Food re-distribution' },
  // ],
};

export default SocialValueDropdown;

import React from 'react';
import RootModal from 'containers/rootModalContainer';
import RootToast from 'components/Toast';

import { iconAuctionSvg, iconDashboardSvg, iconSettingSvg } from 'public/images/icons';
import NotificationsDropdown from './NotificationsDropdown';
import SettingsDropdown from './SettingsDropdown';
import Header from './HeaderWrapper';

const CustomerPortalHeader = () => (
  <>
    <RootModal />
    <RootToast />
    <Header>
      <Header.Nav>
        <Header.Nav.Item image={iconAuctionSvg} link="/my-account" name="My account" />
        <Header.Nav.Item image={iconDashboardSvg} link="/my-account/scrap-my-car" name="Scrap my car" />
        <Header.Nav.Item image={iconSettingSvg} link="/my-account/settings" name="Settings" />
        {/* <Header.Nav.Item image={iconSettingSvg} link="/my-account/my-orders" name="My orders" /> */}
      </Header.Nav>
      <ul className="option-links">
        <NotificationsDropdown />
        <SettingsDropdown />
      </ul>
    </Header>
  </>
);

export default CustomerPortalHeader;

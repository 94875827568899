import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import HeaderNav from './HeaderNav';

const HeaderWrapper = ({ children }) => (
  <header id="header">
    <Container fluid>
      <Row>
        <Col className="header-wrap">{children}</Col>
      </Row>
    </Container>
  </header>
);

HeaderWrapper.Nav = HeaderNav;

HeaderWrapper.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
};

export default HeaderWrapper;

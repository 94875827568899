import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'server/pages';

import { logoPng } from 'public/images';
import HeaderNavItem from './HeaderNavItem';

const HeaderNav = ({ children, logoRoute, showNavMenu }) => {
  const wrapperRef = useRef(null);
  const collapseRef = useRef(null);
  const [isOutside, clickOutside] = useState(true);

  const handlerNavClick = () => clickOutside(!isOutside);

  const handlerOutsideClick = () => clickOutside(true);

  useEffect(() => {
    let touchStartY;
    const handleClickOutside = event => {
      if (collapseRef.current && !collapseRef.current.contains(event.target)) {
        handlerOutsideClick();
      }
    };
    const recordTouchStart = event => {
      if (collapseRef.current && collapseRef.current.contains(event.target)) {
        touchStartY = event.touches ? event.touches[0].screenY : event.screenY;
      }
    };
    const preventTouchMove = event => {
      let allowScroll = false;
      if (collapseRef.current && collapseRef.current.contains(event.target)) {
        const el = collapseRef.current;
        const currentTouchY = event.touches ? event.touches[0].screenY : event.screenY;
        const isOverTop = touchStartY <= currentTouchY && el.scrollTop === 0;
        const isOverBottom = touchStartY >= currentTouchY && el.scrollHeight - el.scrollTop <= el.offsetHeight;
        if (!(isOverBottom || isOverTop)) {
          allowScroll = true;
        }
      }

      if (!allowScroll) {
        if (event.cancelable) {
          event.preventDefault();
        }
        event.stopPropagation();
      }
    };
    if (!isOutside) {
      document.addEventListener('click', handleClickOutside);
      document.querySelector('body').style.overflow = 'hidden';
      if (window && window.navigator && window.navigator.userAgent.match(/iPhone|iPad/)) {
        document.addEventListener('touchstart', recordTouchStart, { passive: false });
        document.addEventListener('touchmove', preventTouchMove, { passive: false });
      }
    }
    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('touchstart', recordTouchStart);
      document.removeEventListener('touchmove', preventTouchMove);
      document.querySelector('body').style.overflow = 'auto';
    };
  }, [isOutside]);

  return (
    <>
      <div className="logo">
        <Link route={logoRoute}>
          <a>
            <img src={logoPng} alt="CAR.CO.UK®" />
          </a>
        </Link>
      </div>
      {showNavMenu && (
        <nav ref={wrapperRef} id="nav" className="navbar navbar-dark">
          <button
            onClick={handlerNavClick}
            className={isOutside ? 'navbar-toggler collapsed' : 'navbar-toggler'}
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded={isOutside}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <div className="navbar-wrap" ref={collapseRef}>
              <ul className="navbar-nav mr-auto">
                {typeof children === 'function' ? children(clickOutside) : children}
              </ul>
            </div>
          </div>
        </nav>
      )}
    </>
  );
};

HeaderNav.propTypes = {
  children: PropTypes.func.isRequired,
  logoRoute: PropTypes.string,
  showNavMenu: PropTypes.bool,
};

HeaderNav.defaultProps = {
  logoRoute: '/',
  showNavMenu: true,
};

HeaderNav.Item = HeaderNavItem;

export default HeaderNav;
